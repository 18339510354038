import React, { createRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Text from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./App.scss";
import { useHistory } from "react-router-dom";
import { CangoLogoText } from "./images/cango-logo-text";
import { colors } from "./theme/colors";
import { CangoLogoSunglow } from "./images/cango-logo-sunglow";
import { LoginTeamIlustration } from "./images/login-team-rafiki";
import { useIsMobile } from "./components/useMobile";

const App = () => {
  const history = useHistory();
  const descriptionRef = createRef<HTMLDivElement>();

  const isMobile = useIsMobile();

  const scrollToBottom = () => {
    descriptionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const goToWorkspace = () => {
    window.location.href = "https://workspace.cango.app";
  };

  const goToPrivacyPolicy = () => {
    history.push("/privacy-policy");
  };

  return (
    <Box textAlign="center" position="relative" sx={{ minHeight: "100vh" }}>
      <Box
        height="100px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={{ mobile: 2, laptop: 8 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={{ mobile: 1, laptop: 1 }}
          sx={{ height: "50%" }}
        >
          <Box mb={1} display="flex" flexDirection="row">
            <CangoLogoSunglow
              width={isMobile ? 40 : 50}
              height={isMobile ? 40 : 50}
            />
            <Box m={{ mobile: 1.5, laptop: 2 }}>
              <CangoLogoText fill={colors.feldgrau[80]} />
            </Box>
          </Box>
        </Box>
        <div>
          <Button
            variant="contained"
            endIcon={<LoginIcon />}
            onClick={goToWorkspace}
          >
            Sign in
          </Button>
        </div>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={{ mobile: 2, laptop: 0 }}
      >
        <Text
          fontSize={{ mobile: 24, laptop: 36 }}
          fontWeight={750}
          color={colors.neutral[80]}
        >
          <span>
            Try{" "}
            <span
              style={{
                backgroundColor: colors.sunglow["20"],
                borderRadius: "10px",
              }}
            >
              efficiency
            </span>
            , you might like it.
          </span>
        </Text>
        <Box
          mb={1}
          mt={{ mobile: 2, laptop: 3 }}
          display="flex"
          flexDirection={{ mobile: "column", tablet: "row" }}
        >
          <Button
            variant="contained"
            sx={{ mr: { tablet: 1 }, mb: { mobile: 1, tablet: 0 } }}
            onClick={goToWorkspace}
          >
            Get Started
          </Button>
          <Button variant="outlined" onClick={scrollToBottom} sx={{ ml: 1 }}>
            Learn More
          </Button>
        </Box>

        <LoginTeamIlustration width={isMobile ? 300 : 500} />
      </Box>
      <Box ref={descriptionRef} bgcolor={colors.feldgrau[60]} color="white">
        <Box py={4} px={{ mobile: 2, laptop: 10 }}>
          <Text mb={3}>
            Cango is a <b>Process Engineering</b> platform where consultants and
            business leaders collaborate to model and optimise businesses.
          </Text>
          <Text mb={3}>
            In a regular business, processes are stored in siloed collections of
            SOPs, training manuals and individual people’s heads. A Cango
            business models its processes, deploys the models and then improves
            the models using the elevated understanding gained from putting them
            into practice.
          </Text>
          <Text mb={3}>
            The <b>“Cango Business Continuum”</b> is the continuous process by
            which Blueprints (templated business Models) update live Projects
            (instances of the Blueprints put into Action) deployed with
            improvements they receive based on <b>insights</b> and other{" "}
            <b>learnings</b> gained from running a variety of projects.
          </Text>
        </Box>
      </Box>

      <Box height="55px" />
      <Paper
        sx={{
          marginTop: "calc(10% + 60px)",
          width: "100%",
          position: "fixed",
          bottom: 0,
        }}
        component="footer"
        square
        variant="outlined"
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            sx={{
              my: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "200px",
            }}
          >
            <Text variant="caption" color="initial">
              Copyright ©2023
            </Text>
            <Text variant="caption" color="initial">
              Hungerford Consulting Ltd
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mr={2}>
            <Button onClick={goToPrivacyPolicy} variant="text">
              Privacy Policy
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default App;
