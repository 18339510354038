import * as React from 'react'

import { colors } from "../theme/colors";

export const CangoLogoSunglow: React.ComponentType<{ width?: number, height?: number }> = ({
  width = 80, height = 80
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 106 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width='106' height='106' rx="13" fill={colors.sunglow[60]} />
    <g clip-path="url(#clip0_545_4621)">
      <path
        d="M24.3355 81.266C23.2904 81.266 22.4423 82.1235 22.4423 83.1801V91.2249C22.4423 92.2815 23.2904 93.139 24.3355 93.139C25.3806 93.139 26.2288 92.2815 26.2288 91.2249V83.1801C26.2288 82.1235 25.3816 81.266 24.3355 81.266Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M24.3355 60.1088C23.2904 60.1088 22.4423 60.9663 22.4423 62.0228V70.0677C22.4423 71.1243 23.2904 71.9818 24.3355 71.9818C25.3806 71.9818 26.2288 71.1243 26.2288 70.0677V62.0228C26.2288 60.9653 25.3816 60.1088 24.3355 60.1088Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M38.9516 80.2783H34.7495C33.7044 80.2783 32.8562 81.1358 32.8562 82.1924V91.2249C32.8562 92.2815 33.7044 93.139 34.7495 93.139C35.7946 93.139 36.6428 92.2815 36.6428 91.2249V84.1065H38.9516C39.9967 84.1065 40.8449 83.249 40.8449 82.1924C40.8449 81.1358 39.9967 80.2783 38.9516 80.2783Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M34.7486 72.4765C35.7937 72.4765 36.6419 71.619 36.6419 70.5624V63.443H37.0575C38.1026 63.443 38.9507 62.5855 38.9507 61.5289C38.9507 60.4724 38.1026 59.6149 37.0575 59.6149H34.7486C33.7035 59.6149 32.8553 60.4724 32.8553 61.5289V70.5624C32.8553 71.619 33.7035 72.4765 34.7486 72.4765Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M38.9516 39.7841H34.7495C33.7044 39.7841 32.8562 40.6416 32.8562 41.6981V50.7316C32.8562 51.7882 33.7044 52.6457 34.7495 52.6457C35.7946 52.6457 36.6428 51.7882 36.6428 50.7316V43.6122H38.9516C39.9967 43.6122 40.8449 42.7547 40.8449 41.6981C40.8449 40.6416 39.9967 39.7841 38.9516 39.7841Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M43.6848 21.8062H34.7495C33.7044 21.8062 32.8562 22.6637 32.8562 23.7202V32.7537C32.8562 33.8103 33.7044 34.6678 34.7495 34.6678C35.7946 34.6678 36.6428 33.8103 36.6428 32.7537V25.6343H43.6848C44.7299 25.6343 45.5781 24.7768 45.5781 23.7202C45.5781 22.6637 44.7299 21.8062 43.6848 21.8062Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M56.722 45.5532C61.0282 43.731 62.1765 43.0534 62.2929 42.9816C62.8543 42.639 63.1998 42.0265 63.2074 41.3632L63.2509 37.1771C63.2576 36.5771 63.0408 36.0555 62.5703 35.688C62.5589 35.6784 61.4902 34.6649 61.1172 31.7211C61.0472 31.165 60.7405 30.6683 60.2757 30.3621C59.9936 30.1754 59.6698 30.0702 59.3413 30.0529C59.1946 29.8481 59.0356 29.5227 59.0081 29.3447C58.9343 28.2949 58.0359 27.4967 56.9975 27.5694C55.9543 27.6374 55.1629 28.5475 55.2301 29.6031C55.3039 30.7554 56.0537 32.4561 57.3004 33.3385C57.3913 33.4026 57.484 33.4619 57.5787 33.5165C58.0444 35.7636 58.8491 37.137 59.4559 37.9055L59.4331 40.165C58.6844 40.5268 57.3931 41.1192 55.2679 42.0198C50.9172 43.8391 47.4809 45.2766 45.8338 47.8749C45.4135 48.5353 44.2945 50.2915 50.2186 97.3281C50.3407 98.2947 51.1558 99 52.0939 99C52.1743 99 52.2548 98.9952 52.3362 98.9847C53.3737 98.8507 54.1064 97.8918 53.9748 96.8438C51.8989 80.3578 48.8147 53.4085 49.1782 49.7172C50.3123 48.2348 53.1579 47.0433 56.722 45.5532Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M94.0251 63.423H91.1889C90.691 56.2213 89.9877 49.3124 89.0779 47.8787C87.729 45.7493 84.6079 44.5301 80.9937 43.1175C80.0972 42.7672 79.1695 42.4054 78.2522 42.0217C76.1213 41.1201 74.8292 40.5258 74.0823 40.165L74.0605 37.9045C74.6673 37.1369 75.47 35.7636 75.9377 33.5174C76.039 33.4581 76.1402 33.3939 76.2378 33.3241C77.3377 32.5345 78.2181 30.9238 78.2853 29.5773C78.3393 28.5207 77.5356 27.6096 76.4915 27.557C75.4407 27.511 74.5584 28.3044 74.5054 29.36C74.4817 29.5332 74.3237 29.8529 74.1769 30.0548C73.8466 30.0721 73.5237 30.1764 73.2407 30.363C72.7778 30.6692 72.4701 31.1659 72.3982 31.721C72.0281 34.6142 70.9915 35.6449 70.9991 35.6449C70.5295 36.0114 70.2588 36.579 70.2645 37.1781L70.3052 41.3642C70.3118 42.0274 70.6573 42.6399 71.2187 42.9825C71.3351 43.0543 72.4824 43.7319 76.7963 45.557C77.7495 45.956 78.7037 46.3293 79.6267 46.6901C82.1978 47.694 85.1049 48.8291 85.8518 49.8914C86.3583 51.1853 87.0219 58.0176 87.5075 65.2299C87.5046 65.2662 87.4961 65.3007 87.4961 65.338C87.4961 65.4787 87.5141 65.6146 87.5416 65.7476C87.6182 66.9037 87.6892 68.0656 87.7555 69.214L72.9879 85.0894C72.9009 85.1832 72.8261 85.2827 72.7617 85.388H63.9892V67.2511H74.5366C75.5817 67.2511 76.4299 66.3936 76.4299 65.3371C76.4299 64.2805 75.5817 63.423 74.5366 63.423H62.0969C61.0518 63.423 60.2036 64.2805 60.2036 65.3371V87.3002C60.2036 88.3567 61.0518 89.2142 62.0969 89.2142H82.6295V97.0859C82.6295 98.1425 83.4776 99 84.5227 99C85.5678 99 86.416 98.1425 86.416 97.0859V89.2142H94.0241C95.0692 89.2142 95.9174 88.3567 95.9174 87.3002V65.3371C95.9183 64.2795 95.0702 63.423 94.0251 63.423ZM92.1318 85.3861H77.9095L91.0706 71.2372C91.4256 70.8563 91.6102 70.3433 91.5818 69.8208C91.5354 68.9805 91.4862 68.1192 91.4341 67.2511H92.1308L92.1318 85.3861Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M63.8246 49.3747V57.5813C63.8246 58.6378 64.6728 59.4953 65.7179 59.4953C66.7629 59.4953 67.6111 58.6378 67.6111 57.5813V49.3747C67.6111 48.3181 66.7629 47.4606 65.7179 47.4606C64.6728 47.4606 63.8246 48.3171 63.8246 49.3747Z"
        fill={colors.feldgrau[100]}
      />
      <path
        d="M54.4263 18.7493L54.3885 19.1283C54.2749 20.2385 54.1196 21.7592 54.4074 24.0475C54.5399 25.0974 55.4951 25.8324 56.525 25.7041C57.5625 25.5701 58.2952 24.6112 58.1636 23.5632C57.9308 21.7142 58.05 20.5505 58.1551 19.5226L58.1949 19.1168C58.4259 16.7252 60.2623 14.2771 63.1818 13.1411L62.2342 16.4055C61.9388 17.4209 62.5144 18.4842 63.5169 18.7809C63.6948 18.8345 63.8747 18.8594 64.0508 18.8594C64.8696 18.8594 65.6241 18.3177 65.8664 17.4832L67.3101 12.5065C71.9628 12.7477 75.0214 15.9825 75.329 19.2058C75.4047 20.0088 75.5183 21.2003 75.4786 22.5746H62.0969C61.0518 22.5746 60.2036 23.4321 60.2036 24.4887C60.2036 25.5453 61.0518 26.4028 62.0969 26.4028H77.2308C78.1841 26.4028 78.9878 25.6859 79.1089 24.7308C79.4213 22.2463 79.2102 20.0308 79.0891 18.7484C78.6508 14.146 74.5348 9.40478 68.2614 8.74155C68.1933 7.98166 67.6802 7.30504 66.9125 7.07822C65.9071 6.77962 64.8573 7.36246 64.5629 8.37501L64.4247 8.85544C61.8186 9.29376 59.6243 10.446 57.9563 11.9859C57.8976 11.9802 57.8418 11.9677 57.7822 11.9677H24.3356C23.2905 11.9677 22.4423 12.8252 22.4423 13.8818V34.3692H12.9759C11.9308 34.3692 11.0826 35.2267 11.0826 36.2832V91.225C11.0826 92.2816 11.9308 93.1391 12.9759 93.1391C14.021 93.1391 14.8692 92.2816 14.8692 91.225V38.1973H22.4423V50.7326C22.4423 51.7892 23.2905 52.6467 24.3356 52.6467C25.3807 52.6467 26.2288 51.7892 26.2288 50.7326V36.9446C26.2288 36.8316 26.2146 36.7225 26.1957 36.6144C26.2156 36.5062 26.2288 36.3962 26.2288 36.2832V15.7959H55.2158C54.7946 16.7596 54.521 17.7569 54.4263 18.7493Z"
        fill={colors.feldgrau[100]}
      />
    </g>
    <defs>
      <clipPath id="clip0_545_4621">
        <rect width="91" height="92" fill="white" transform="translate(8 7)" />
      </clipPath>
    </defs>
  </svg>
);
//91 92
