import React from "react";

export const LoginTeamIlustration: React.ComponentType<{ width?: number }> = ({
  width = 500,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width={width}>
      <g>
        <path fill="#ebebeb" d="M0 382.4H500V382.65H0z"></path>
        <path fill="#ebebeb" d="M416.78 398.49H449.9V398.74H416.78z"></path>
        <path
          fill="#ebebeb"
          d="M322.53 401.21H331.21999999999997V401.46H322.53z"
        ></path>
        <path fill="#ebebeb" d="M396.59 389.21H415.78V389.46H396.59z"></path>
        <path fill="#ebebeb" d="M52.46 390.89H95.65V391.14H52.46z"></path>
        <path fill="#ebebeb" d="M104.56 390.89H110.89V391.14H104.56z"></path>
        <path fill="#ebebeb" d="M131.47 395.11H225.15V395.36H131.47z"></path>
        <path
          fill="#ebebeb"
          d="M237 337.8H43.91a5.71 5.71 0 01-5.7-5.71V60.66a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71v271.38a5.71 5.71 0 01-5.71 5.71zM43.91 55.2a5.46 5.46 0 00-5.45 5.46v271.43a5.46 5.46 0 005.45 5.46H237a5.47 5.47 0 005.46-5.46V60.66A5.47 5.47 0 00237 55.2zM453.31 337.8h-193.1a5.72 5.72 0 01-5.71-5.71V60.66a5.72 5.72 0 015.71-5.66h193.1a5.71 5.71 0 015.69 5.66v271.43a5.71 5.71 0 01-5.69 5.71zM260.21 55.2a5.47 5.47 0 00-5.46 5.46v271.43a5.47 5.47 0 005.46 5.46h193.1a5.47 5.47 0 005.46-5.46V60.66a5.47 5.47 0 00-5.46-5.46z"
        ></path>
        <path fill="#f5f5f5" d="M82.64 101.91H177.31V315.18H82.64z"></path>
        <path
          fill="#fafafa"
          d="M27.34 164.78H232.61V252.31H27.34z"
          transform="rotate(90 129.985 208.545)"
        ></path>
        <path fill="#f5f5f5" d="M128.97 104.88H130.98V313.18H128.97z"></path>
        <path
          fill="#f5f5f5"
          d="M80.46 99.13H179.5V109.16999999999999H80.46z"
        ></path>
        <path fill="#f5f5f5" d="M80.46 110.28H179.5V111.47H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 112.98H179.5V114.17H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 115.68H179.5V116.87H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 118.38H179.5V119.57H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 121.08H179.5V122.27H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 123.78H179.5V124.97H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 126.48H179.5V127.67H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 129.18H179.5V130.37H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 131.88H179.5V133.07H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 134.58H179.5V135.77H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 137.28H179.5V138.47H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 139.98H179.5V141.17H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 142.68H179.5V143.87H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 145.38H179.5V146.57H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 148.08H179.5V149.27H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 150.78H179.5V151.97H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 153.48H179.5V154.67H80.46z"></path>
        <path fill="#f5f5f5" d="M80.46 156.18H179.5V157.37H80.46z"></path>
        <path fill="#f5f5f5" d="M194.73 101.91H289.4V315.18H194.73z"></path>
        <path
          fill="#fafafa"
          d="M139.43 164.78H344.70000000000005V252.31H139.43z"
          transform="rotate(90 242.065 208.545)"
        ></path>
        <path fill="#f5f5f5" d="M241.06 104.88H243.07V313.18H241.06z"></path>
        <path
          fill="#f5f5f5"
          d="M192.55 99.13H291.59000000000003V109.16999999999999H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 110.28H291.59000000000003V111.47H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 112.98H291.59000000000003V114.17H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 115.68H291.59000000000003V116.87H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 118.38H291.59000000000003V119.57H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 121.08H291.59000000000003V122.27H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 123.78H291.59000000000003V124.97H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 126.48H291.59000000000003V127.67H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 129.18H291.59000000000003V130.37H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 131.88H291.59000000000003V133.07H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 134.58H291.59000000000003V135.77H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 137.28H291.59000000000003V138.47H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 139.98H291.59000000000003V141.17H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 142.68H291.59000000000003V143.87H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 145.38H291.59000000000003V146.57H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 148.08H291.59000000000003V149.27H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 150.78H291.59000000000003V151.97H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 153.48H291.59000000000003V154.67H192.55z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.55 156.18H291.59000000000003V157.37H192.55z"
        ></path>
        <path fill="#f5f5f5" d="M306.82 101.91H401.49V315.18H306.82z"></path>
        <path
          fill="#fafafa"
          d="M251.52 164.78H456.79V252.31H251.52z"
          transform="rotate(90 354.155 208.545)"
        ></path>
        <path
          fill="#f5f5f5"
          d="M353.15 104.88H355.15999999999997V313.18H353.15z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M304.63 99.13H403.67V109.16999999999999H304.63z"
        ></path>
        <path fill="#f5f5f5" d="M304.63 110.28H403.67V111.47H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 112.98H403.67V114.17H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 115.68H403.67V116.87H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 118.38H403.67V119.57H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 121.08H403.67V122.27H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 123.78H403.67V124.97H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 126.48H403.67V127.67H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 129.18H403.67V130.37H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 131.88H403.67V133.07H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 134.58H403.67V135.77H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 137.28H403.67V138.47H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 139.98H403.67V141.17H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 142.68H403.67V143.87H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 145.38H403.67V146.57H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 148.08H403.67V149.27H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 150.78H403.67V151.97H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 153.48H403.67V154.67H304.63z"></path>
        <path fill="#f5f5f5" d="M304.63 156.18H403.67V157.37H304.63z"></path>
        <path
          fill="#f0f0f0"
          d="M150.31 277.42h12.47v-.83c0-1.63-1.83-2.93-4-2.84l-4.88.2c-2 .08-3.59 1.33-3.59 2.84z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M185 277.84h16.49l2.63-23a1.62 1.62 0 00-1.62-1.54h-11.05a1.62 1.62 0 00-1.61 1.54zM159.82 219.4h4.12l4.63 43.5h-4.12a1.42 1.42 0 01-1.37-1.24l-4.36-41a1.09 1.09 0 011.1-1.26z"
        ></path>
        <path
          fill="#ebebeb"
          d="M166.19 262.9h61.72a1.09 1.09 0 001.1-1.24l-4.36-41a1.43 1.43 0 00-1.37-1.24h-61.72a1.09 1.09 0 00-1.1 1.24l4.36 41a1.42 1.42 0 001.37 1.24z"
        ></path>
        <path
          fill="#fafafa"
          d="M167.34 258.88h58.2a1 1 0 001-1.08L222.7 222a1.24 1.24 0 00-1.19-1.08h-58.2a1 1 0 00-1 1.08l3.81 35.79a1.23 1.23 0 001.22 1.09z"
        ></path>
        <rect
          width="22.08"
          height="1.79"
          x="183.49"
          y="276.05"
          fill="#e6e6e6"
          rx="0.88"
          transform="rotate(180 194.525 276.95)"
        ></rect>
        <path
          fill="#e6e6e6"
          d="M105.41 277.32H155.65V284.9H105.41z"
          transform="rotate(180 130.535 281.115)"
        ></path>
        <path
          fill="#e6e6e6"
          d="M245.42 382h1.25a.3.3 0 00.29-.29l2-96.79h-5.8l2 96.79a.29.29 0 00.26.29z"
        ></path>
        <path
          fill="#f0f0f0"
          d="M290.13 381.98L293.03 381.98 284.85 284.9 279.06 284.9 290.13 381.98z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M103.45 381.98L103.45 381.98 114.56 284.9 108.76 284.9 100.58 381.98 103.45 381.98z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M141.32 277.32H288.2V284.9H141.32z"
          transform="rotate(180 214.765 281.115)"
        ></path>
        <path
          fill="#f0f0f0"
          d="M145.91 381.98L149.24 381.98 149.24 381.98 150.47 284.9 144.68 284.9 145.91 381.98z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M211.83 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.29.29 0 00-.28.22l-.44 1.62a.3.3 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M209.7 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.1a.3.3 0 00-.29.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M207.57 276h1.54a.29.29 0 00.3-.29V274a.29.29 0 00-.3-.29H208a.31.31 0 00-.29.22l-.43 1.62a.29.29 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M205.45 276H207a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.29.29 0 00-.28.22l-.44 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M203.32 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.3.3 0 00-.28.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M201.19 276h1.54a.29.29 0 00.3-.29V274a.29.29 0 00-.3-.29h-1.1a.3.3 0 00-.29.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M199.07 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.29.29 0 00-.28.22l-.44 1.62a.29.29 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M196.94 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.29.29 0 00-.28.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M194.81 276h1.54a.3.3 0 00.3-.29V274a.3.3 0 00-.3-.29h-1.1a.3.3 0 00-.29.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M192.69 276h1.53a.29.29 0 00.3-.29V274a.29.29 0 00-.3-.29h-1.1a.29.29 0 00-.28.22l-.44 1.62a.29.29 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M190.56 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29H191a.29.29 0 00-.28.22l-.44 1.62a.3.3 0 00.28.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M188.43 276H190a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.1a.3.3 0 00-.29.22l-.43 1.62a.29.29 0 00.25.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M186.31 276h1.53a.29.29 0 00.3-.29V274a.29.29 0 00-.3-.29h-1.1a.29.29 0 00-.28.22l-.44 1.62a.29.29 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M184.18 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.11a.29.29 0 00-.28.22l-.44 1.62a.3.3 0 00.29.45z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M182.05 276h1.54a.29.29 0 00.29-.29V274a.29.29 0 00-.29-.29h-1.1a.3.3 0 00-.29.22l-.43 1.62a.29.29 0 00.28.45z"
        ></path>
        <path
          fill="#ebebeb"
          d="M180.5 274.85H214.91V277.41H180.5z"
          transform="rotate(180 197.71 276.135)"
        ></path>
        <path
          fill="#e0e0e0"
          d="M279.36 311.84h62.46V315a5 5 0 01-5 5h-52.46a5 5 0 01-5-5v-3.12-.04z"
          transform="rotate(180 310.59 315.895)"
        ></path>
        <path
          fill="#e0e0e0"
          d="M339.79 366.18l-20-2v-28.09h-7.93v28.12l-20.09 2a3.9 3.9 0 00-3.52 3.88v3h55.05v-3a3.9 3.9 0 00-3.51-3.91z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M295.54 376.79a5.19 5.19 0 11-5.19-5.18 5.19 5.19 0 015.19 5.18zM336 376.79a5.19 5.19 0 105.19-5.18 5.19 5.19 0 00-5.19 5.18z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M310.48 317.08h10.66V338a4.13 4.13 0 01-4.13 4.13h-2.4a4.13 4.13 0 01-4.13-4.13v-20.92z"
        ></path>
        <path
          fill="#ebebeb"
          d="M305.27 312.36a345.4 345.4 0 0010.31-73.31 10.29 10.29 0 0110.29-9.9h18.47a10.29 10.29 0 0110.3 10.25c.07 16.82-1.1 51.21-10.59 79.16l-35.14-1.3a3.9 3.9 0 01-3.64-4.9z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M310.24 316.68a363.09 363.09 0 0011.05-76.43 9.49 9.49 0 019.49-9.13h17a9.51 9.51 0 019.51 9.45c.06 15.52-1 50.33-9.77 76.11z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M317.54 292.16s-20.23-4.09-39.77-2.11 9.23 27.52 9.23 27.52h4s-15.22-16.19-14.37-20.22 39.19 1.28 39.19 1.28z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M347.55 316.68H314.4a362.82 362.82 0 0011.06-76.43 9.49 9.49 0 019.49-9.13h12.86a9.5 9.5 0 019.51 9.46c.06 15.51-1.02 50.32-9.77 76.1z"
        ></path>
        <rect
          width="71.08"
          height="4.06"
          x="277.13"
          y="315.9"
          fill="#e0e0e0"
          rx="2"
          transform="rotate(-180 312.675 317.925)"
        ></rect>
        <path
          fill="#e0e0e0"
          d="M93.73 362.1a2.38 2.38 0 00-1.91-3.42 5.15 5.15 0 00-1.62-5.59 1.23 1.23 0 00-.71-.31 1.32 1.32 0 00-.75.34l-4.47 3.21a2.65 2.65 0 00-.89.87c-.91 1.77 2.38 4 1 5.5-.39.42-1 .51-1.5.81-1.07.67-1.19 2.13-1.69 3.28s-2.22 2-2.89 1c.49-1.11-1-2.06-2.19-2.2s-2.45-.2-3.11-1.33c-1.71 1.49-5.52-2.66-5.43-4.34a3.3 3.3 0 00-2.66 2.73 19.35 19.35 0 00-.14 4.17c0 2.71-.92 5.37-.87 8.08 0 2.11.85 4.45 2.78 5.3a7.21 7.21 0 003 .4l22-.14a3.22 3.22 0 001.54-.26c1-.51 1.21-1.82 1.3-2.93q.58-7.17 1.18-14.34z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M95 338.86H60.89l.28 3.44.2 2.47.52 6.4.3 3.68.39 4.9.17 2.1v.63L63 365l.27 3.38.17 2.1.17 2.13.2 2.47.19 1.82.16 2.1.08.92h31.29l.07-.92.17-2.09.16-1.89.2-2.46.17-2.13.17-2.1.27-3.39.2-2.46v-.63l.17-2.1.4-4.9.3-3.67.51-6.41.2-2.47.28-3.44zm2 2.26l-.65-.66h.71zm-.16 2.09l-.07.83-3 3.06-3.51-3.5 3.14-3.14h.74zM96 354.13l-2.23 2.24-3.51-3.51 3.51-3.49 2.42 2.42zm-32.47-2.34l2.47-2.42 3.51 3.49-3.51 3.51-2.22-2.24zm29.11-3.56l-3.51 3.5-3.5-3.5 3.5-3.5zm-5.51-7.77h4l-2 2zm.86 12.4l-3.49 3.51-3.5-3.51 3.51-3.49zm-11.62 4.64l3.5-3.5 3.5 3.5-3.5 3.5zm2.37 4.63l-3.51 3.51-3.49-3.5 3.5-3.5zm5.77-3.49l3.49 3.5-3.49 3.5-3.51-3.51zm-4.64-6.91l-3.5-3.5 3.5-3.5 3.5 3.5zm-1.13 1.13l-3.5 3.51-3.5-3.51 3.5-3.49zm-8.14-1.13l-3.5-3.5 3.5-3.5 3.5 3.5zm0 2.27l3.5 3.5-3.5 3.5-3.5-3.5zm3.5 12.77l-3.5 3.5-3.5-3.5 3.5-3.5zm1.13 1.13l3.51 3.51-3.5 3.5-3.5-3.5zm1.14-1.13l3.5-3.5 3.5 3.5-3.5 3.5zm8.14 1.13l3.49 3.51-3.49 3.5-3.51-3.5zm4.63 4.64l3.51 3.5-2.27 2.27h-2.47L85.64 376zm-3.5-5.77l3.5-3.5 3.51 3.5-3.51 3.5zm0-9.27l3.5-3.5 3.51 3.5-3.51 3.5zM88 343.6l-3.49 3.5-3.51-3.5 3.14-3.14h.74zm-8.13-1.14l-2-2h4zm-1.13 1.14l-3.5 3.5-3.5-3.5 3.13-3.14h.74zm-6.14-3.14l-2 2-2-2zm-6.27 0l3.14 3.14-3.47 3.5-3-3.06-.07-.83 2.75-2.75zm-3 0l-.65.65v-.65zm-.21 6.05l1.71 1.72-1.46 1.46zm.95 11.75l-.13-1.66.89.9-.76.76zm.17 2.11l1.76-1.73 3.51 3.5-3.51 3.5-1.41-1.42zm.52 6.48v-.16l.08.08-.07.08zm.61 7.46l-.44-5.37 1-1 3.51 3.51L66 374.9l-.59-.59zm.32 4l-.06-.8.33-.34 1.14 1.14zM67.1 376l3.5-3.5 3.5 3.5-2.27 2.27h-2.47zm7 2.27l1.14-1.14 1.14 1.14zm4.54 0L76.37 376l3.5-3.5 3.5 3.5-2.26 2.27zm4.73 0l1.14-1.14 1.14 1.14zm10.68 0h-1.41l1.14-1.14.33.34zm.33-4l-.6.59-3.51-3.49 3.51-3.51 1 1zm.62-7.62v.16l-.07-.08.09-.08zm.2-2.47l-1.42 1.42-3.51-3.5 3.51-3.5 1.73 1.73zm.48-6l-.77-.77.9-.9zm.95-11.76l-.26 3.19-1.46-1.47 1.72-1.72z"
        ></path>
        <rect
          width="42.34"
          height="4.46"
          x="58.7"
          y="337.43"
          fill="#f0f0f0"
          rx="1.64"
          transform="rotate(180 79.87 339.66)"
        ></rect>
        <rect
          width="36.23"
          height="4.46"
          x="61.76"
          y="377.52"
          fill="#f0f0f0"
          rx="1.64"
        ></rect>
        <path
          fill="#ebebeb"
          d="M408.06 346l10.64-30.64s-14.7 10.96-14.65-1.16 6.72-2.7 9-7.18-6.54-4.43-3.44-11.22 11.92 3.64 13.48-2.37 4-9.07 10.46-8.53c0 0 3.47 7.91-.79 13.62s10.52 1.14 9 6.89-6.43 10.77-8.6 6.72-4.93 3.71-1.22 5.71-4.85 10.66-6.73 6.12a47.45 47.45 0 00-4.83-8.58l-9.8 32.78z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M397.11 346.3l-12-19.59s11.86 5.33 10-3-5.06-.86-7.32-3.61 3.85-4.05.68-8.28-7.69 4.32-9.68.4-4.17-5.67-8.53-4.33c0 0-1.2 6 2.6 9.31s-7.1 2.38-5.19 6.13 6.07 6.48 7 3.35 4 1.82 1.71 3.77 5 6.64 5.58 3.21a33.84 33.84 0 012.05-6.66l11.73 21.2z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M405.18 346.49l-5.49-31.56s12.64 13 14.47 1.17-6.12-3.71-7.64-8.44 7.08-3.28 5.13-10.39-12.19 1.65-12.75-4.46-2.5-9.49-8.84-10c0 0-4.65 7.16-1.41 13.41s-10.44-.57-9.89 5.28 4.56 11.52 7.32 7.92 4.22 4.4.28 5.76 3 11.16 5.59 7a47.22 47.22 0 016.07-7.6l4.34 33.52z"
        ></path>
        <path
          fill="#ebebeb"
          d="M412 382h-18.86a2.5 2.5 0 01-2.49-2.34l-2-32.5a2.49 2.49 0 012.49-2.64H414a2.49 2.49 0 012.48 2.64l-2 32.5A2.5 2.5 0 01412 382z"
        ></path>
        <rect
          width="32.52"
          height="4.99"
          x="386.18"
          y="343.17"
          fill="#f5f5f5"
          rx="2.48"
        ></rect>
      </g>
      <g>
        <ellipse
          cx="250"
          cy="416.24"
          fill="#f5f5f5"
          rx="193.89"
          ry="11.32"
        ></ellipse>
      </g>
      <g>
        <path
          fill="#67A08F"
          d="M173.28 114.24a14.72 14.72 0 10-24.42 9.26l.46 1.73 2.42.29 10.64 1.24 2.43.29.84-1.59a14.67 14.67 0 007.63-11.22z"
        ></path>
        <path
          fill="#fff"
          d="M173.28 114.24a14.72 14.72 0 10-24.42 9.26l.46 1.73 2.42.29 10.64 1.24 2.43.29.84-1.59a14.67 14.67 0 007.63-11.22z"
          opacity="0.7"
        ></path>
        <path
          fill="none"
          stroke="#67A08F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
          d="M160.9 126.82s1.53-6.95 5.28-9.82l-3-.35-.71-3.31-2.94 5.66-1-5.33-2.22 5-1.55-6.2-1.46 3.06-3-.35c3 3.65 2.87 10.76 2.87 10.76"
        ></path>
        <path
          fill="#263238"
          d="M162.22 135.23c-.22 1.9-3.15 3.13-6.54 2.73s-6-2.26-5.73-4.17 3.15-3.12 6.54-2.73 5.95 2.26 5.73 4.17z"
        ></path>
        <path
          fill="#67A08F"
          d="M161.15 136.34l-10.42-1.22a2.57 2.57 0 01-2.28-2.71l.41-7.22a.5.5 0 01.55-.46l15.42 1.81a.49.49 0 01.43.57l-1.26 7.12a2.57 2.57 0 01-2.85 2.11z"
        ></path>
        <path
          fill="#fff"
          d="M161.15 136.34l-10.42-1.22a2.57 2.57 0 01-2.28-2.71l.41-7.22a.5.5 0 01.55-.46l15.42 1.81a.49.49 0 01.43.57l-1.26 7.12a2.57 2.57 0 01-2.85 2.11z"
          opacity="0.3"
        ></path>
        <path
          fill="#67A08F"
          d="M148.69 127.23l16.27 1.9a.65.65 0 00.15-1.29l-16.27-1.9a.65.65 0 00-.15 1.29zM164.57 130.92l-15.92-1.86a.65.65 0 10-.15 1.29l15.92 1.86a.64.64 0 00.72-.57.64.64 0 00-.57-.72zM163.91 134l-15.32-1.79a.65.65 0 00-.72.57.66.66 0 00.57.73l15.32 1.79a.66.66 0 00.72-.57.67.67 0 00-.57-.73z"
        ></path>
        <g fill="#fff" opacity="0.5">
          <path d="M148.69 127.23l16.27 1.9a.65.65 0 00.15-1.29l-16.27-1.9a.65.65 0 00-.15 1.29zM164.57 130.92l-15.92-1.86a.65.65 0 10-.15 1.29l15.92 1.86a.64.64 0 00.72-.57.64.64 0 00-.57-.72zM163.91 134l-15.32-1.79a.65.65 0 00-.72.57.66.66 0 00.57.73l15.32 1.79a.66.66 0 00.72-.57.67.67 0 00-.57-.73z"></path>
        </g>
        <path
          fill="#67A08F"
          d="M159.06 91.08L159.53 75.2 162.79 74.95 159.06 91.08z"
        ></path>
        <path
          fill="#67A08F"
          d="M171.07 97.26L182.71 81.36 184.06 82.88 171.07 97.26z"
        ></path>
        <path
          fill="#67A08F"
          d="M177.38 108.19L191.31 106.09 191.8 107.55 177.38 108.19z"
        ></path>
        <path
          fill="#67A08F"
          d="M145.77 95.05L134.69 82.59 133.19 84.42 145.77 95.05z"
        ></path>
        <path
          fill="#67A08F"
          d="M139.29 107.78L124.13 104.12 124.63 106.14 139.29 107.78z"
        ></path>
      </g>
      <g>
        <g>
          <path
            fill="#eec1bb"
            d="M338.2 186.49l-12.63 11.35c-2 1.77-15.53-12.39-15.53-12.39l-3.72 3.22S319 204.4 323.7 204.74s17.76-10 17.76-10z"
          ></path>
          <path
            fill="#67A08F"
            d="M330.16 192.65l4.81 8.17 5-3 8.36-5.12v-18.44s-13.14 8.61-18.17 18.39z"
          ></path>
          <path
            d="M340 197.77l8.36-5.12v-.12c-.58-2.22-1.07-4-1.07-4s-8.74 4.15-7.29 9.24z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#eec1bb"
            d="M400.08 196.8s7.09 8.48 6.17 12.64-7.15 6.5-7.15 6.5l-7.1-.64s6-4.31 6-5.86-4.45-5.75-4.45-5.75z"
          ></path>
          <path
            fill="#67A08F"
            d="M342.24 238.64l39.4 2.76a371.62 371.62 0 011.53-37.65q.33-3.62.66-6.84c1.23-11.61 2.49-19.15 2.49-19.15h-.12l-37.89-3.48c-7.9 26.99-6.07 64.36-6.07 64.36z"
          ></path>
          <path
            fill="#eec1bb"
            d="M370.82 400.33L364.18 404.15 356.21 389.7 362.85 385.88 370.82 400.33z"
          ></path>
          <path
            fill="#263238"
            d="M363.37 403.3l7.38-4.7a.62.62 0 01.8.07l4.45 4.63a1.1 1.1 0 01-.12 1.56l-.19.13c-2.57 1.58-4 2.29-7.18 4.33-2 1.25-10.34 5.75-13.43 6s-3.44-2.71-2.18-3.18c6.35-2.37 8.82-5.39 9.63-7.77a2.05 2.05 0 01.84-1.07z"
          ></path>
          <path
            style={{ isolation: "isolate" }}
            d="M362.85 385.88L356.21 389.7 360.32 397.15 366.97 393.33 362.85 385.88z"
            opacity="0.2"
          ></path>
          <path
            fill="#263238"
            d="M342.25 238.65s-19.69 64.23-18.49 85.65c1.3 23.49 32.88 72.9 32.88 72.9l11.9-7.52S346.24 341 345.4 323c1.23-26.08 25.37-82.29 25.37-82.29z"
          ></path>
          <path
            d="M361.56 256.12c-6.57 4-9.18 21.18-7.79 29.89 3.56-11 7.62-21.9 10.87-30.24a3.1 3.1 0 00-3.08.35z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#67A08F"
            d="M355.32 398.35L369.35 389.46 367.05 385.5 352.34 394.48 355.32 398.35z"
          ></path>
          <path
            fill="#67A08F"
            d="M361.85 405.73a2.42 2.42 0 01-1.44.48.93.93 0 01-.76-.56.55.55 0 010-.58c.52-.81 3.32-.94 3.64-.94a.14.14 0 01.15.1.17.17 0 010 .19 10.15 10.15 0 01-1.59 1.31zm-1.71-.66a.59.59 0 00-.18.17.2.2 0 000 .23.6.6 0 00.51.39 3.89 3.89 0 002.37-1.35 7.58 7.58 0 00-2.7.56z"
          ></path>
          <path
            fill="#67A08F"
            d="M363.39 404.46h-.09c-.87.09-3-.44-3.22-1.21a.6.6 0 01.27-.69 1 1 0 01.76-.23c1.1.1 2.29 1.78 2.33 1.88a.15.15 0 010 .18.18.18 0 01-.05.07zm-2.77-1.65l-.06.05c-.19.15-.15.24-.14.29.15.45 1.58.94 2.52 1-.38-.47-1.19-1.37-1.88-1.43a.67.67 0 00-.44.1z"
          ></path>
          <path
            fill="#eec1bb"
            d="M385.3 409.02L377.91 409.02 377.3 391.91 384.7 391.91 385.3 409.02z"
          ></path>
          <path
            fill="#263238"
            d="M376.89 408.16h9a.67.67 0 01.63.51l1.46 6.58a1.09 1.09 0 01-.84 1.29 1 1 0 01-.24 0c-2.91 0-5-.22-8.67-.22-2.24 0-9 .23-12.12.23s-3.5-3.06-2.23-3.34c5.69-1.24 10-3 11.8-4.6a1.83 1.83 0 011.21-.45z"
          ></path>
          <path
            style={{ isolation: "isolate" }}
            d="M384.7 391.92L377.3 391.92 377.62 400.74 385.01 400.74 384.7 391.92z"
            opacity="0.2"
          ></path>
          <path
            fill="#eec1bb"
            d="M375.36 177.1c-1.6 1.45-9.89 2.39-9.89 2.39l-3.19 4.81-1.51-6.85s-2.75-.65-2.17-1.51a6.84 6.84 0 004.68-2.48c1.4-1.91 1.3-4.58.85-7.18v-.1l6.45-4.76 4.44-3.3c-1.44 5.32-3.19 15.12.34 18.98z"
          ></path>
          <path
            d="M373.2 162.7c-1.37 6.25-7 9.88-9.92 10.76 1.4-1.91 1.3-4.58.85-7.18a.37.37 0 000-.11l6.45-4.76c.14.97 2.82.33 2.62 1.29z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#263238"
            d="M356.22 239.62s4.18 63.26 7.3 84.68c3.41 23.49 10.71 74.73 10.71 74.73h13.51s-2.76-49-4-72.13c-1.47-26.31-2.06-85.5-2.06-85.5z"
          ></path>
          <path
            fill="#67A08F"
            d="M372.6 399.32L388.54 399.32 388.54 394.56 372 394.25 372.6 399.32z"
          ></path>
          <path
            fill="#263238"
            d="M365.22 135.81a6.64 6.64 0 00-7.07 2.62c-3.11 3.94-.83 6.56-.83 6.56a6.92 6.92 0 011.43-3.06s-1.82 4 2.12 8.73 15.54 10.15 15.54 10.15 7.06-2.77 9.1-9.08 2-10.8-5.39-14.89-14.7-5.27-14.9-1.03z"
          ></path>
          <path
            fill="#eec1bb"
            d="M378.1 151c-.83 6.43-1.09 10.53-3.88 13.79A10.94 10.94 0 01373 166c-5.94 5.26-14.71 1.28-16-6.13a23.65 23.65 0 01-.3-4.07c0-6.31 2.31-13.82 8.29-15.78a10 10 0 0113.08 11z"
          ></path>
          <path
            fill="#263238"
            d="M366.88 152.26c0 .59-.38 1.05-.78 1s-.67-.54-.63-1.13.4-1.05.78-1 .67.52.63 1.13zM360.16 151.79c0 .59-.39 1.05-.78 1s-.66-.54-.63-1.13.4-1.05.78-1 .67.56.63 1.13z"
          ></path>
          <path
            fill="#d4827d"
            d="M362.28 152.23a21.09 21.09 0 01-3.2 4.88 3.47 3.47 0 002.82.74z"
          ></path>
          <path
            fill="#263238"
            d="M369.32 149.94a.39.39 0 01-.36-.09 2.81 2.81 0 00-2.38-.84.34.34 0 01-.41-.27.35.35 0 01.26-.41 3.46 3.46 0 013 1 .35.35 0 010 .49zM363.53 161a5.36 5.36 0 004.08-2.81.18.18 0 00-.09-.24.17.17 0 00-.24.08 5.15 5.15 0 01-3.8 2.59.17.17 0 00-.16.19.18.18 0 00.2.16zM357.57 148.39a.35.35 0 01-.19-.1.34.34 0 01.09-.48 3.47 3.47 0 013.17-.42.36.36 0 01.18.46.36.36 0 01-.46.18 2.81 2.81 0 00-2.5.37.39.39 0 01-.29-.01z"
          ></path>
          <path
            fill="#263238"
            d="M360.77 142.6c1.7-.56 3.41 1 3.41 1a39.71 39.71 0 016.84 0c2.23.38 2.88 2.61 3.07 3.16s-.88 1.64-.88 1.64.24 6.73.33 8.59l2.75 5a30.35 30.35 0 004.77-15.99c.09-9.57-18.78-9.11-18.78-9.11z"
          ></path>
          <path
            fill="#eec1bb"
            d="M380.83 159.26a7 7 0 01-4.54 2.77c-2.42.35-3.32-1.88-2.4-4 .84-1.87 3.1-4.39 5.4-3.75s2.86 3.14 1.54 4.98z"
          ></path>
          <path
            fill="#67A08F"
            d="M374.45 409.46a2.15 2.15 0 01-1.42-.38 1 1 0 01-.37-.93.52.52 0 01.3-.49c.82-.43 3.2 1 3.47 1.21a.16.16 0 01.08.17.18.18 0 01-.13.15 9 9 0 01-1.93.27zm-1.1-1.53a.37.37 0 00-.23.05c-.05 0-.1.06-.11.2a.74.74 0 00.23.63 3.52 3.52 0 002.63.13 7.18 7.18 0 00-2.52-1.01z"
          ></path>
          <path
            fill="#67A08F"
            d="M376.33 409.2h-.08c-.76-.42-2.24-2-2.09-2.82a.58.58 0 01.57-.46.94.94 0 01.74.22c.85.7 1 2.82 1 2.93a.21.21 0 01-.08.16.17.17 0 01-.06-.03zm-1.48-3h-.09c-.22 0-.24.13-.25.18-.09.48.85 1.71 1.61 2.28a4.14 4.14 0 00-.88-2.31.58.58 0 00-.39-.15z"
          ></path>
          <path
            fill="#eec1bb"
            d="M307.53 189.74s-7.53-.65-9.22-1.27-1.27-6-1.15-6.89 10.54 1 12.17 1.82 2.53 4.79 2.53 4.79z"
          ></path>
          <path
            fill="#67A08F"
            d="M337.15 210.24H424.96999999999997V270.2H337.15z"
            transform="rotate(4.41 381.2 240.352)"
          ></path>
          <path
            transform="rotate(4.41 381.2 240.352)"
            style={{ isolation: "isolate" }}
            fill="#fff"
            d="M337.15 210.24H424.96999999999997V270.2H337.15z"
            opacity="0.7"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="0.5"
            d="M337.15 210.24H424.96999999999997V270.2H337.15z"
            transform="rotate(4.41 381.2 240.352)"
          ></path>
          <path
            d="M338.69 206.05H340.48V207.84H338.69z"
            transform="rotate(4.41 339.863 207.09)"
          ></path>
          <path
            d="M334.08 265.83H335.87V267.62H334.08z"
            transform="rotate(4.41 335.276 266.96)"
          ></path>
          <path
            d="M421.63 272.59H423.42V274.38H421.63z"
            transform="rotate(4.41 422.8 273.734)"
          ></path>
          <path
            d="M426.25 212.81H428.04V214.6H426.25z"
            transform="rotate(4.41 427.123 213.744)"
          ></path>
          <path
            fill="#67A08F"
            d="M337.07 260.13H421.32V268.46999999999997H337.07z"
            transform="rotate(4.41 379.398 264.48)"
          ></path>
          <path
            d="M413.05 267.61l-67.81-5.23a.76.76 0 01-.7-.81.76.76 0 01.82-.7l67.8 5.23a.76.76 0 01.7.81.76.76 0 01-.81.7z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#67A08F"
            d="M372.05 241.48L373.09 228.04 390.28 236.13 372.05 241.48z"
          ></path>
          <path
            fill="#67A08F"
            d="M355.5 262.47a1.6 1.6 0 11-1.47-1.72 1.61 1.61 0 011.47 1.72z"
          ></path>
          <path
            d="M355.5 262.47a1.6 1.6 0 11-1.47-1.72 1.61 1.61 0 011.47 1.72z"
            style={{ isolation: "isolate" }}
            fill="#fff"
            opacity="0.4"
          ></path>
          <path
            fill="#67A08F"
            d="M374.55 173.46l-9.08 4.31-3.19 6.23v-7s-1.29-1.4 0-4.14l-5.61 2.14-2.53 6.44 6.54-2.73 1.6 6.72 3.19-5.32 4.09 4.59 8.65-7.72z"
          ></path>
          <path
            d="M374.55 173.46l-9.08 4.31-3.19 6.23v-7s-1.29-1.4 0-4.14l-5.61 2.14-2.53 6.44 6.54-2.73 1.6 6.72 3.19-5.32 4.09 4.59 8.65-7.72zM381.53 196.57a23.32 23.32 0 001.64 7.18q.33-3.62.66-6.84z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#67A08F"
            d="M386.32 177.77C394 181.12 404 199.48 404 199.48l-8.86 8.07-13.65-11z"
          ></path>
          <path
            fill="#eec1bb"
            d="M388.58 211.36s1-4.9 1.95-5 8.2 1.18 8.65 2.52.83 4.63-1 4.82-10.18 0-9.6-2.34z"
          ></path>
        </g>
      </g>
      <g>
        <path
          fill="#2e353a"
          d="M238.11 187.92c1.54-1.71 5.34-4.73 1-8.23s-2.65-10.18 2-10.05.3-2.89.49-8.23 7-6 10.91-3.29 7-4.28 12-3.06 3.22 5.6 4.35 10.2 8.47.82 10.74 6-1.59 4.88-1.23 10.5 4.95 6.64 4.51 10.95-9.65 4.76-9 11.09-3.21 11.36-8.38 8.74-4.15 5.56-11 3.76-7.88-6.73-8-11.59-4.83-1.18-7.95-3.25-5.37-8.04-.44-13.54z"
        ></path>
        <path
          fill="#67A08F"
          d="M234.22 241.68L236.68 198.87 274 200.1 267.1 244.53 234.22 241.68z"
        ></path>
        <path
          style={{ isolation: "isolate" }}
          fill="#fff"
          d="M234.22 241.68L236.68 198.87 274 200.1 267.1 244.53 234.22 241.68z"
          opacity="0.7"
        ></path>
        <path
          fill="#9f5b53"
          d="M237.76 251.13s-10.18 54.27-12.9 76.55c-3.55 29.07-18.06 71.6-18.06 71.6l5.8 1.5s20.46-36.14 24.45-68.65c16.26-33.24 17.57-74.13 17.57-74.13z"
        ></path>
        <path
          fill="#2e353a"
          d="M196 412.44a8.35 8.35 0 013-.76s3.57-2.1 7.11-10.26h4a3.28 3.28 0 013.06 2.18 2.15 2.15 0 01.11.55 22.88 22.88 0 01-.3 2.87 79.29 79.29 0 01-2.33 9.36l-.67-.17 1.22-5.94c.41-2-1.71-.07-2.69 2s-2.49 4.08-6.88 3.07c-5.18-1.25-7.25-1.98-5.63-2.9z"
        ></path>
        <path
          fill="#9f5b53"
          d="M248.34 250.26s1.26 55.21 3.21 77.57c2.54 29.18-2.86 73.79-2.86 73.79l6 .26s12.55-39.59 9.73-72.23c9-35.87 1.85-76.18 1.85-76.18z"
        ></path>
        <path
          fill="#2e353a"
          d="M239.36 414.25a8.55 8.55 0 012.89-1.07s3.32-2.48 5.95-11l4-.45a3.28 3.28 0 013.28 1.84 2 2 0 01.17.54 23.06 23.06 0 010 2.88 80.35 80.35 0 01-1.3 9.55l-.69-.09.57-6c.19-2.05-1.71.12-2.46 2.25s-2 4.32-6.51 3.8c-5.26-.65-7.39-1.16-5.9-2.25z"
        ></path>
        <path
          fill="#9f5b53"
          d="M251.14 204.66c7.64-1 9.94-4.24 9.94-4.24-4.85-4.57-.82-12.5-.82-12.5l-9.2 1.72a28.05 28.05 0 01.36 3.87c.07 5.58-2.46 5.64-2.46 5.64-1.61 3.02 2.18 5.51 2.18 5.51z"
        ></path>
        <path
          d="M251.06 189.64a28.05 28.05 0 01.36 3.87c6.21 0 8.84-5.59 8.84-5.59z"
          style={{ isolation: "isolate" }}
          opacity="0.2"
        ></path>
        <path
          fill="#9f5b53"
          d="M256.14 163.91c-8-.44-10.8 3.7-11.84 11.91-1.3 10.26.59 17.7 10.71 16.05 13.74-2.24 14.41-27.23 1.13-27.96z"
        ></path>
        <path
          fill="#892b29"
          d="M249.49 175.66a16.74 16.74 0 01-2.51 3.19 2.22 2.22 0 002.06 1z"
        ></path>
        <path
          fill="#2e353a"
          d="M254.42 175.27c0 .66.27 1.22.7 1.25s.84-.47.89-1.12-.26-1.22-.7-1.25-.83.47-.89 1.12z"
        ></path>
        <path
          fill="#2e353a"
          d="M255.49 174.19l-1.38-.67s.61 1.26 1.38.67zM246.28 174.5c-.05.65.26 1.21.7 1.25s.83-.47.88-1.13-.26-1.21-.7-1.25-.83.47-.88 1.13z"
        ></path>
        <path
          fill="#2e353a"
          d="M247.34 173.42l-1.38-.67s.62 1.25 1.38.67z"
        ></path>
        <path
          fill="none"
          stroke="#2e353a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.725"
          d="M254.86 169.64a4.19 4.19 0 013.3 1.63M249 168.9a3.71 3.71 0 00-3.14.88"
        ></path>
        <path
          fill="#2e353a"
          d="M264.14 177.87s-3.38-6.78-2.53-11.82c0 0-15.49-7.34-17.16 8.7 0 0-.76-14.37 13-13.93s12.61 20.54 5.18 26.15c0 .03 3.6-4.87 1.51-9.1z"
        ></path>
        <path
          fill="#9f5b53"
          d="M262.75 179.48a3.5 3.5 0 013.95-3c2.46.4 3.36 5.55-2.87 6.44-.89.14-1.34-.75-1.08-3.44z"
        ></path>
        <path
          fill="none"
          stroke="#2e353a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.725"
          d="M253.86 180.23s-.6 3-4.64 3"
        ></path>
        <path
          fill="#67A08F"
          d="M234.28 242.07l-19.55 114.84s47.3 5.64 63.34 2.34c0 0 3.93-82.19-12.81-114.62z"
        ></path>
        <path
          fill="#9f5b53"
          d="M206.8 399.28s-5 10.39-7.83 12.4c0 0 3.83 2.28 7.83-2.8 5.16-6.58 6.42-4.75 6.42-4.75a18.78 18.78 0 00-.62-3.35c-.78-2.12-5.8-1.5-5.8-1.5zM249.29 399.2s-1.41 3.84-3.28 8.07a18.36 18.36 0 01-3.76 5.91s3.26 1.06 5-1 4.78-9.44 8.16-8.59a7.91 7.91 0 00-.76-1.72c-.48-.82-5.36-2.67-5.36-2.67z"
        ></path>
        <path d="M234.22 241.68L233.3 247.82 267.78 250.26 267.24 243.62 234.22 241.68z"></path>
        <path
          fill="#fff"
          d="M251 242.38c-.37-.4-9.39-1.12-10.13-.39 0 0-.93 6.1-.56 6.67s9.07.64 9.88.7 1.16-6.58.81-6.98zm-1.62 6.29c-.66 0-7.81-.1-8.11-.57s.46-5.48.46-5.48c.61-.6 8 0 8.32.32s-.05 5.78-.69 5.73z"
        ></path>
        <path
          fill="#fff"
          d="M249.17 244.66l-1.78-.12a.64.64 0 00-.69.55.65.65 0 00.61.76l1.79.1a.66.66 0 00.08-1.31z"
        ></path>
        <path
          fill="#9f5b53"
          d="M274 200.1c6.27 4.08 1.91 36.89-24.78 62l-2.95-3.68s17.31-23.93 19.35-33.89c4.59-22.43 8.38-24.43 8.38-24.43zM236.68 198.87c-3-.81-8.68 17.12-8.68 33.56 0 9.25 2.71 27.07 2.71 27.07l4.72-3s-1.55-25.73-1.21-28.1 3.85-17.89 3.85-17.89a29 29 0 001.09-6.78 6.51 6.51 0 00-2.48-4.86z"
        ></path>
        <path
          fill="#67A08F"
          d="M201.46 263.82H289.28V323.78H201.46z"
          transform="rotate(2.3 245.614 294.084)"
        ></path>
        <path
          transform="rotate(2.3 245.614 294.084)"
          style={{ isolation: "isolate" }}
          fill="#fff"
          d="M201.46 263.82H289.28V323.78H201.46z"
          opacity="0.7"
        ></path>
        <path
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M201.46 263.82H289.28V323.78H201.46z"
          transform="rotate(2.3 245.614 294.084)"
        ></path>
        <path
          d="M201.8 261.19H203.59V262.98H201.8z"
          transform="rotate(2.3 202.864 262.3)"
        ></path>
        <path
          d="M199.39 321.1H201.17999999999998V322.89000000000004H199.39z"
          transform="rotate(2.3 200.323 321.905)"
        ></path>
        <path
          d="M287.14 324.63H288.93V326.42H287.14z"
          transform="rotate(2.3 288.104 325.376)"
        ></path>
        <path
          d="M289.55 264.72H291.34000000000003V266.51000000000005H289.55z"
          transform="rotate(2.3 290.9 266.015)"
        ></path>
        <path
          fill="#67A08F"
          d="M283.21 274.12l-50.28-2a.75.75 0 01-.72-.79.76.76 0 01.78-.73l50.28 2a.75.75 0 01.73.78.76.76 0 01-.79.74zM283 279.64l-50.29-2a.75.75 0 01-.72-.78.75.75 0 01.79-.73l50.28 2a.76.76 0 01.72.79.76.76 0 01-.78.72zM282.76 285.15l-50.28-2a.76.76 0 01-.73-.79.75.75 0 01.79-.72l50.28 2a.77.77 0 01.73.79.76.76 0 01-.79.72zM282.53 290.67l-50.28-2a.77.77 0 01-.73-.79.76.76 0 01.79-.73l50.28 2a.77.77 0 01.73.79.76.76 0 01-.79.73zM282.3 296.19l-73.47-3a.76.76 0 01-.73-.79.76.76 0 01.79-.73l73.47 3a.76.76 0 01.73.79.77.77 0 01-.79.73zM282.07 301.7l-73.47-3a.76.76 0 01-.73-.79.76.76 0 01.79-.72l73.48 3a.75.75 0 01.72.78.75.75 0 01-.79.73zM281.85 307.22l-73.48-3a.77.77 0 01-.73-.79.77.77 0 01.79-.73l73.48 3a.77.77 0 01.73.79.77.77 0 01-.79.73zM281.62 312.73l-73.48-3a.75.75 0 01-.72-.78.75.75 0 01.78-.73l73.48 3a.77.77 0 01.73.79.76.76 0 01-.79.72zM281.39 318.25l-73.47-3a.76.76 0 01-.73-.79.77.77 0 01.79-.73l73.47 3a.76.76 0 01.73.79.76.76 0 01-.79.73zM216.91 274.22l-4.16-.17.06-1.52 10.05.42-.07 1.51-4.16-.17-.44 10.64-1.72-.07z"
        ></path>
        <path
          fill="none"
          stroke="#67A08F"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M208.67 269.9H226.5V287.72999999999996H208.67z"
          transform="rotate(2.36 217.756 279.16)"
        ></path>
        <path
          fill="#9f5b53"
          d="M247.08 257.32s-3 1.38-3.4 2.18-3.74 9.7-3.47 10.87 5.69 2.62 6.47 2.33 3.87-10.54 3.86-11.83-3.46-3.55-3.46-3.55zM232.37 254.46s-2.66 1.9-2.94 2.77-1.9 10.21-1.42 11.31 6.07 1.54 6.79 1.11 1.87-11.07 1.62-12.34-4.05-2.85-4.05-2.85z"
        ></path>
        <path
          fill="#67A08F"
          d="M259.39 197.18s-4.54 5-7.85 6.56c0 0-1.85-4.06-1-6.56 0 0-5.75 3.27-5.18 7.48s5.76.61 5.76.61 4.41 4 8 1.75 2.79-7.32 2.79-7.32zM251.19 209.76a.77.77 0 01-.77.76.77.77 0 010-1.53.77.77 0 01.77.77zM249.71 219a.77.77 0 11-.77-.77.76.76 0 01.77.77z"
        ></path>
      </g>
      <g>
        <g>
          <path
            fill="#e4897b"
            d="M172.75 179.16c-.65-1.44 0-10.15 0-10.15s10.72-5.62 12.43-7 3.65 4.83 2.57 6.12-4.28 6.13-9.64 6.13l-.72 7.08z"
          ></path>
          <path
            fill="#263238"
            d="M141.73 178.58c3.34.81 19.44 11.52 22.72 11.16s7.15-15 7.15-15l6.69 2s-.32 26.71-6.44 29.6-31.28-2.62-31.28-2.62z"
          ></path>
          <path
            fill="#fff"
            d="M178.28 176.76s-.31 26.72-6.43 29.61c-2.16 1-6.66 1-11.6.54a190.54 190.54 0 01-19.69-3.16l.15-3.06 1-22.1c3.33.79 19.43 11.51 22.71 11.14s7.16-15 7.16-15z"
            opacity="0.2"
          ></path>
          <path
            d="M160.25 206.91a190.54 190.54 0 01-19.69-3.16l.15-3.06c.67-3 1.29-5.59 1.29-5.59s18.15 3.9 18.25 11.81z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#263238"
            d="M109.47 407.65H100a.68.68 0 00-.67.54l-1.54 7a1.16 1.16 0 00.89 1.37 1.06 1.06 0 00.25 0c3.09-.05 5.33-.24 9.2-.24 2.38 0 9.57.25 12.86.25s3.72-3.25 2.38-3.55c-6-1.31-10.59-3.14-12.53-4.87a1.89 1.89 0 00-1.37-.5zM149.86 407.4h-9.52a.68.68 0 00-.67.54l-1.55 7a1.16 1.16 0 00.9 1.37.69.69 0 00.25 0c3.08-.05 5.32-.24 9.19-.24 2.39 0 9.58.25 12.87.25s3.72-3.25 2.37-3.55c-6-1.31-10.59-3.14-12.52-4.87a1.89 1.89 0 00-1.32-.5z"
          ></path>
          <path
            fill="#67A08F"
            d="M153.49 408.55H139.4s-1.3-44.24 2.6-75.93c0 0-3.87-19-7.25-36.88-1.75-9.23-3.37-18.17-4.27-24.06-3.51 46.25-8.87 59.41-8.87 59.41-3 47.18-9.31 77.4-9.31 77.4H98c-1.33-37.17 5.75-82.54 5.75-82.54-4.54-52.53-4.78-88.87-4.71-94.33h55.24c3.92 21.6 15.04 90.38-.79 176.93z"
          ></path>
          <path d="M154.24 231.62L98.99 231.62 98.99 236.61 154.94 236.09 154.24 231.62z"></path>
          <path
            fill="#67A08F"
            d="M116.75 229.38H118.72V238.23H116.75z"
            transform="rotate(180 117.735 233.805)"
          ></path>
          <path
            fill="#67A08F"
            d="M144.7 229.38H146.67V238.23H144.7z"
            transform="rotate(180 145.685 233.805)"
          ></path>
          <path
            fill="#fff"
            d="M129.06 230.75H139.28V236.33H129.06z"
            transform="rotate(180 134.165 233.54)"
          ></path>
          <path
            fill="#263238"
            d="M154.24 231.62H99c-.26-2.38-.45-6.63-.59-11.72-.15-5.63-.25-12.28-.31-18.53-.11-11.45-.1-21.54-.1-21.54s7-6.42 24-6.37a44.36 44.36 0 0120 5.26c12.24 31.41 12.24 52.9 12.24 52.9z"
          ></path>
          <path
            fill="#e4897b"
            d="M109.68 176.12c4.82 4.47 13.78 5.9 13.78 5.9s6.43-3.36 8.35-6.05c-3.25-2.51-3.93-2.74-5-4.63a6.43 6.43 0 01-.66-3.67 13.92 13.92 0 01.32-2.09l-16.79-7.33c2.39 5.23 5.14 13.75 0 17.87z"
          ></path>
          <path
            d="M113.7 163.21c1 6.5 9.13 7.94 13.11 8.13a6.43 6.43 0 01-.66-3.67z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#263238"
            d="M123.1 139.07s10.27 3.77 4 15.06c-3.36 6-9.88-13.19-9.88-13.19z"
          ></path>
          <path
            fill="#e4897b"
            d="M114.06 166.85a9.53 9.53 0 0011.54.32 10.54 10.54 0 004.34-6.49 9.88 9.88 0 00.19-1.11c.74-6.94-1.72-17.92-9.44-19.95a10.33 10.33 0 00-12.8 12.06c1.41 7.42 1.88 11.84 6.17 15.17z"
          ></path>
          <path
            fill="#263238"
            d="M119.52 152.31c0 .61.46 1.06.87 1s.65-.59.58-1.19-.47-1.06-.87-1-.65.57-.58 1.19z"
          ></path>
          <path
            fill="#e4897b"
            d="M128.8 152.43a5.87 5.87 0 013.7 6.53c-1.11 7.48-9.74 13.29-18.44 7.89-7.79-4.85 14.74-14.42 14.74-14.42z"
          ></path>
          <path
            fill="#263238"
            d="M126.41 151.42c0 .61.47 1.06.86 1s.66-.59.58-1.2-.47-1.06-.86-1-.65.62-.58 1.2z"
          ></path>
          <path
            fill="#de5753"
            d="M124.26 152a21.8 21.8 0 003.59 4.84 3.58 3.58 0 01-2.86.92z"
          ></path>
          <path
            fill="#263238"
            d="M128.56 148.26a.39.39 0 01-.38 0 2.91 2.91 0 00-2.6-.16.35.35 0 01-.48-.14.36.36 0 01.14-.49 3.61 3.61 0 013.29.15.37.37 0 01.15.49.37.37 0 01-.12.15zM117.81 149.74a.32.32 0 01-.21 0 .37.37 0 010-.51 3.55 3.55 0 013.05-1.25.36.36 0 01.3.4.37.37 0 01-.41.3 2.87 2.87 0 00-2.4 1 .35.35 0 01-.33.06zM112.18 141.64l.89 1.29c-2.31 1.24-1.32 4-.59 5.2 1.55 2.57.75 7.12-1.06 9.2 0 0-.28-1.18-1.24-.72s.43 6.63 2.92 9.24c0 0-3.45-2.15-6.29-8.38-2.62-5.75-4.93-11.46-2-15.83z"
          ></path>
          <path
            fill="#e4897b"
            d="M105.42 158.24a7.15 7.15 0 004.54 3.07c2.47.49 3.51-1.77 2.67-4-.77-2-3-4.68-5.38-4.14s-3.1 3.11-1.83 5.07z"
          ></path>
          <path
            fill="#263238"
            d="M121.25 142.43s7.05-4.65 5.71-8.21-3 .91-3 .91.66-2.92-1.38-3.06-1.17 1.45-2.1 2.66-11.64 1-14.75 3.55.42 9.46.42 9.46 5.42-7.74 15.1-5.31zM125.75 160.6a23.55 23.55 0 00-1.74 2.77l-.55-.19c-2.46-.87-3.31-2.09-3.51-3.18a3.42 3.42 0 01.12-1.59 3.19 3.19 0 01.38-.83 12.22 12.22 0 004.47 2.71c.51.21.83.31.83.31z"
          ></path>
          <path
            fill="#fff"
            d="M124.92 160.29l-.54.8c-2.31-.82-3.83-1.72-4.31-2.68a3.19 3.19 0 01.38-.83 12.22 12.22 0 004.47 2.71z"
          ></path>
          <path
            fill="#de5753"
            d="M123.46 163.18c-2.46-.87-3.31-2.09-3.51-3.18a8.61 8.61 0 012.54 1.51 3.17 3.17 0 01.97 1.67z"
          ></path>
          <path
            fill="#67A08F"
            d="M127.29 179.02L125.05 184.94 130.9 215.25 128.72 218.52 125.05 215.54 123 185.63 117.48 179.41 123 177.99 127.29 179.02z"
          ></path>
          <path
            fill="#263238"
            d="M111.87 171.45L123.1 176.92 127.84 171.89 133.37 175.24 129.66 183.84 123.46 179.11 119.16 184.34 107.78 175.17 111.87 171.45z"
          ></path>
          <path
            fill="#fff"
            d="M111.87 171.45L123.1 176.92 127.84 171.89 133.37 175.24 129.66 183.84 123.46 179.11 119.16 184.34 107.78 175.17 111.87 171.45z"
            opacity="0.2"
          ></path>
          <path
            d="M98.41 219.9c-.15-5.63-.25-12.28-.31-18.53l4.69-4s.68 16.43-4.38 22.53zM134.75 295.74c-1.75-9.23-3.37-18.17-4.27-24.06a20 20 0 004.87-9.44s2.28 17.92-.6 33.5z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
          ></path>
          <path
            fill="#e4897b"
            d="M72.07 204.75l-7.52-3.47s-2.43-1.92-4-1.76-11.46 1.59-12 1.76 1 6.74 2.91 6.45 9.2.35 10.17-.78l8.8 6.81z"
          ></path>
          <path
            fill="#263238"
            d="M98.36 179.58c-3.94.44-13.6 22.81-17.39 25.14S68.12 202 68.12 202l-4 9.81s14.9 11 20.15 10.06c10.17-1.75 16.82-16.18 16.82-16.18s3.91-12.18.43-21.41a14.56 14.56 0 00-3.16-4.7z"
          ></path>
          <path
            fill="#fff"
            d="M98.36 179.58c-3.94.44-13.6 22.81-17.39 25.14S68.12 202 68.12 202l-4 9.81s14.9 11 20.15 10.06c10.17-1.75 16.82-16.18 16.82-16.18s3.91-12.18.43-21.41a14.56 14.56 0 00-3.16-4.7z"
            opacity="0.2"
          ></path>
        </g>
      </g>
    </svg>
  );
};
